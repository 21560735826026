.bg-img {
  /* position: relative;
  top: 80px; */
  background: url("https://www.eduloans.org/images/policy.jpg");
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.privacyPolicy {
  position: relative;
  top: 20px;
  height: 210vh;
}
.privacyPolicy h3 {
  position: relative;
  font-weight: 700;
  color: #006fae;
  letter-spacing: 1px;
  margin-bottom: 32px;
}
#info {
  border-radius: 8px;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .bg-img {
    height: 180px !important;
  }
  .privacyPolicy {
    height: 320vh;
  }
}
