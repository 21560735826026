.video-container-pc {
  margin: 6% 0 0% 0;
}

.outer-ul-li-c {
  padding-left: 10px;
}

.inner-ul-li-c {
  padding-left: 10px;
  border-left: 1px solid white;
  cursor: pointer;
  font-size: 14px;
}

.inner-ul-li-c-active {
  color: #f34325;
  /*font-size: 14px;*/
  padding-left: 10px;
  border-left: 1px solid #f34325;
  cursor: pointer;
  font-weight: bold;
}

.ul-li-header-c {
  background: #f34325;
  color: white;
  padding: 15px;
}

.video-container-pclf {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}

.video-sub-menu {
  width: 20%;
}

.right-nav-details {
  background-color: whitesmoke;
  padding: 10px;
  /* margin-top: -65px !important; */
}

.nav-details-h {
  font-size: 16px;
  color: #f34325;
}

.video-list-item {
  width: 78%;
  margin: 2% 0;
}

.accordian-head-c {
  display: flex;
  justify-content: space-between;
  color: #f34325;
}

.panel-toggle {
  width: 100%;
}

.panel-body td,
th {
  border: 1px solid #ddd;
  padding: 5px;
}

.panel-wrap {
  margin-bottom: 5px;
}

.panel-wrap.active-panel .panel-header {
  color: #f34325;
}

.panel-wrap.active-panel .panel-header button {
  color: #f34325;
}

.panel-header {
  /* border: 1px solid #cccc; */
  font-weight: normal;
  border-radius: 5px 5px 0px 0px;
}

.panel-header button {
  border: none;
  background: transparent;
}

.panel-header button:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.panel-body {
  /* border: 1px solid #dddddd; */
  /* background: #efefef; */
  color: #333333;
  padding: 5px;
  border-top: none;
}

/*responsive */
@media (max-width: 768px) {

  /* common css */
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    font-size: 10px;
  }

  .video-container-pc {
    margin-top: 80px !important;
  }

  .video-container-pclf {
    width: 100%;
    overflow: hidden;
  }

  .video-sub-menu {
    width: 25%;
    font-size: 10px;
  }

  .video-list-item {
    margin: 1% 0;
  }

  .right-nav-details {
    overflow: hidden;
    padding: 0 0 0 10px;
    width: 98% !important;
  }

  .inner-ul-li-c {
    font-size: 10px;
  }

  .outer-ul-li-c {
    padding-left: 6px;
  }

  .panel-body {
    padding: 0;
  }

  .panel-wrap.active-panel .panel-header button,
  .accordian-head-c {
    font-size: 12px;
  }

  .btn-md {
    width: 80px !important;
    font-size: 10px !important;
  }

  ul:not(.u-unstyled) {
    margin-top: -8px !important;
  }

  h2:not(.u-subtitle) {
    margin-bottom: 12px;
}

  p:not(.u-text-variant) {
    margin-top: 16px !important;
  }
}

@media(max-width:576px){
  .video-list-item {
    margin: 2% 0;
  }
}
