.banner-sec{
    /* background:url(../img/login/LoginPageImage.png)  no-repeat left bottom;  */
    background-size:contain; 
    min-height:600px; 
    border-radius: 0 10px 10px 0; 
    /*padding:0;*/
    background-position: center center !important;    
    /*margin-top: 5%;*/
    background-repeat: no-repeat !important;
  }


.login-sec{padding: 50px 30px; position:relative;}
.login-sec .copy-text{position:absolute; width:80%; bottom:20px; font-size:13px; text-align:center;}
.login-sec h2{margin-bottom:30px; font-weight:800; font-size:30px; color: #004b7a;}
.login-sec h2:after{content:" "; width:100px; height:5px; background:#f9992f; display:block; margin-top:20px; border-radius:3px; margin-left:auto;margin-right:auto}
.btn-login{background: #f9992f; color:#fff; font-weight:600;}
.form-control{
  display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
  /*.loginContainer{
    background:#fff; border-radius: 10px; box-shadow: 5px 5px 0px rgba(0,0,0,0.1);
  }*/

  .marginTop25{
    margin-top: 25px;
  }

  .loginButton{
    text-align: center; border-radius:5px;    padding: 10px 16px !important;
  }

  .heroLogin {
    width: 100%;
    height: 100vh;
    /* background: url(../img/login/LoginPageBG.png) bottom center no-repeat; */
    background-size: cover !important;
    background-position: bottom center !important;
    margin: 6% 0 0% 0;
  }


.loginBox{
      padding: 8% 6% !important;
      background-color: white !important;
      height: 75% !important;
      border-radius: 5% !important;
      box-shadow: 0px 3px 20px 0px #9a9a9a70 !important;
      margin: auto !important;
  }

.loginInput{
    height: 65px !important;
    margin-bottom: 5% !important;
    background-color: #ff6600 !important;
    border-radius: 12px !important;
    border: 1px solid #ff6600 !important;
    color: white !important;
}


.loginButton1 {
    text-align: center !important;
    width: 40% !important;
    margin-top: 6% !important;
    border-radius: 20px !important;
    background-color: #ff6600 !important;
    border: 1px solid red !important;
    height: 45px !important;
}
.textAlignCenter{
  text-align: center;
}

.CreateInput {
    height: 40px !important;
    margin-bottom: 3% !important;
    background-color: white !important;
    border-radius: 12px !important;
    border: 1px solid #ff6600 !important;
    color: #f34325 !important;
}

.createBox {
    padding: 3% 4% !important;
    background-color: white !important;
    height: 75% !important;
    border-radius: 5% !important;
    box-shadow: 0px 3px 20px 0px #9a9a9a70 !important;
    margin: auto !important;
}

.createButton1 {
    text-align: center !important;
    width: 40% !important;
    /* margin-top: 6% !important; */
    border-radius: 20px !important;
    background-color: #ff6600 !important;
    border: 1px solid red !important;
    height: 45px !important;
}

.contactBox{
    position: absolute;
    padding: 5% 5% !important;
    background-color: white !important;
    /* height: 75% !important; */
    border-radius: 5% !important;
    box-shadow: 0px 3px 20px 0px #9a9a9a70 !important;
    /* margin: 38px 27px !important; */
    top: 15%;
    right: 4%;
}

.contactForm{
  margin: 15px 0px;
}

@media (max-width: 992px) {
  .createBox {
    margin-top: 10% !important;
  }

  .heroLogin {
    height: 65vh;
  }
}

@media (max-width: 556px) {
  .heroLogin {
    width: 90%;
    height: 55vh;
    margin: 12% 0 0% 0;
    margin: 12% auto;
  }
  .banner-sec{
    display: none;
  }
  .loginBox {
    margin-top: 36px !important;
  }
}