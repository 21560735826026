.features .feature-box {
    padding: 24px 20px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
        background-color: white;
  }
  
  .features .feature-box h3 {
    font-size: 18px;
    color: #006FAE;
    font-weight: 700;
    margin: 0;
  }
  
  .features .feature-box i {
    line-height: 0;
    background: #ecf3ff;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    border-radius: 3px;
    transition: 0.3s;
  }
  
  .features .feature-box:hover i {
    background: #4154f1;
    color: #fff;
  }
  
  .features .feture-tabs {
    margin-top: 20px;
  }
  
  .features .feture-tabs h3 {
    color: #006FAE;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }

  .features .feture-tabs p {
    font-size: 19px;
  }

  
  @media (max-width: 768px) {
    .features .feture-tabs h3 {
      font-size: 28px;
    }
  }
  
  .features .feture-tabs .nav-pills {
    border-bottom: 1px solid #eee;
  }
  
  .features .feture-tabs .nav-link {
    background: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #006FAE;
    padding: 12px 0;
    margin-right: 25px;
    margin-bottom: -2px;
    border-radius: 0;
  }
  
  .features .feture-tabs .nav-link.active {
    color: #4154f1;
    border-bottom: 3px solid #4154f1;
  }
  
  .features .feture-tabs .tab-content li {
    /*font-size: 18px;*/
    margin: 0;
    /*font-weight: 700;*/
    color: #006FAE;
  }
  
  .features .feture-tabs .tab-content i {
    font-size: 24px;
    line-height: 0;
    margin-right: 8px;
    color: #4154f1;
  }
  
  .features .feature-icons {
    margin-top: 120px;
  }
  
  .features .feature-icons h3 {
    color: #012970;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .features .feature-icons h3 {
      font-size: 28px;
    }
  }
  
  .features .feature-icons .content .icon-box {
    display: flex;
  }
  
  .features .feature-icons .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #012970;
  }
  
  .features .feature-icons .content .icon-box i {
    font-size: 44px;
    line-height: 44px;
    color: #0245bc;
    margin-right: 15px;
  }
  
  .features .feature-icons .content .icon-box p {
    font-size: 15px;
    color: #848484;
  }

/*.swiper {
  width: 100% important;
  height: 100% important;
}

.swiper-slide {
  text-align: center important;
  font-size: 18px important;
  background: #fff important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block important;
  width: 100% important;
  height: 100% important;
  object-fit: cover important;
}
*/

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 10px 19px 0px rgb(1 41 112 / 25%);
  background: #fff;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
  border-radius: 15px;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

/*.swiper-slide {
    flex-shrink: 0;
    width: 42% !important;
    height: 100%;
    position: relative;
    transition-property: transform;
}*/

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}

.testimonials .swiper-slide {
  opacity: 2;
}

.padingDiv{
      padding: 4% 1%;
}

.imgHome{
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

/*@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }
  .testimonials .swiper-pagination {
    margin-top: 0;
  }
}*/

@media (min-width: 1200px) {
  /*.testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }*/
}
