.qa-c-l01 {
  width: 80%;
  margin: auto;
  position: relative;
}

.timer-float-cla {
  text-align: right;
}

.question-totalnum-c {
  position: absolute;
}

.button-container-c {
  text-align: right;
  margin-right: 10%;
}

.container-fc-if {
  position: relative;
  cursor: pointer;
}

@media(max-width:576px) {
  .qa-c-l01 {
    width: 90%;
  }
}