.about .content {
    background-color: #f6f9ff;
    padding: 40px;
  }
  
  .about h3 {
    font-size: 14px;
    font-weight: 700;
    color: #4154f1;
    text-transform: uppercase;
  }
  
  .about h2 {
    font-size: 24px;
    font-weight: 700;
    color: #012970;
  }

  .txtColorwhite{
    color: white;
  }
  
  .about p {
    margin: 15px 0 30px 0;
    line-height: 24px;
  }
  
  .about .btn-read-more {
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #4154f1;
    box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
  }
  
  .about .btn-read-more span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
  }


  .about .btn-read-more i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
  }

  .pdding4{
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  
  .about .btn-read-more:hover i {
    transform: translateX(5px);
  }

  .marginT5{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  

  .heroAboutus {
    width: 100%;
    height: 100vh;
    /* background: url(../img/aboutus/BackgroundAboutus.png) top center no-repeat; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
        margin: 0% 0 0% 0;
        background-position:top;
  }

  .features .feature-box {
    padding: 15px 15px;
    box-shadow: 5px 6px 7px 0px rgb(132 127 127 / 20%);
    transition: 0.3s;
    height: 100%;
        background-color: ghostwhite;
  }
  
  .features .feature-box h3 {
    font-size: 18px;
    color: #012970;
    font-weight: 700;
    margin: 0;
  }
  
  .features .feature-box .marginLR {
    line-height: 0;
    background: #ecf3ff;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    border-radius: 3px;
    transition: 0.3s;
  }
  
  .features .feature-box:hover .marginLR {
    background: #4154f1;
    color: #fff;
  }

  .marginTB{
        margin: 2% 0px;
  }

  .marginLR{
        margin: 0px 2% ;
  }

  .dspBlock{
    display: block;
  }

  @media(max-width: 576px){
    .col-margin{
      margin-top: 200px !important;
    }
    .marginTop{
      margin-bottom: -40px !important;
    }
  }