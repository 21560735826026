.hero {
  width: 95%;
  height: 845px;
  /* background: url(../img/home/Banner.png) center center no-repeat; */
  background-size: contain !important;
  margin: 5% 0 5% 0;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.heroAboutus {
  width: 100%;
  height: 100vh;
  /* background: url(../img/aboutus/BackgroundAboutus.png) top center no-repeat; */
  background-size: cover;
  margin: 0% 0 0% 0;
}

.heroStudentHome {
  width: 100%;
  height: 100vh;
  /* background: url(../img/HeaderBG.png) bottom center no-repeat; */
  background-size: cover;
  margin: 6% 0 0% 0;
}

.heroPrice {
  width: 100% !important;
  background-size: cover !important;
  background-position: bottom center !important;
  background-repeat: no-repeat !important;
}
.marginLeftSection {
  margin-left: 2.5% !important;
}

.hero h1 {
  font-size: 40px;
  font-weight: 700;
  color: #006fae;
  margin-bottom: 5%;
  text-align: center;
  text-transform: uppercase;
}

.hero h2 {
  color: #444444;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.heroAboutus h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-align: inherit;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.heroPrice h2 {
  color: #fff;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.heroPrice h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: #006fae;
  text-align: inherit;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.heroAboutus h2 {
  color: #fff;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.hero .btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 30px 60px;
  border-radius: 20px;
  transition: 0.5s;
  color: #fff;
  background: #006fae;
  box-shadow: 0px 5px 30px rgb(65 84 241 / 40%);
  text-decoration: none;
}

.hero .btn-get-started span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .hero-img {
  text-align: right;
}

.align-items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}

.heroDiv {
  border-radius: 5%;
  background-color: white;
  padding: 6% 5%;
  margin: 0% 0 0 0;
  box-shadow: 0px 0px 20px 0px #00000029;
  position: absolute !important;
  top: 20%;
  right: 6%;
}

.registerFormControl {
  border: none !important;
  height: 60px !important;
  background-color: #fff9f8 !important;
  border-radius: 15px !important;
  margin-bottom: 4% !important;
  box-shadow: 4px 4px 8px 0px #00000030 !important;
}
section {
  padding: 60px 0;
  overflow: hidden;
}

@media screen and (min-width: 1700px) {
  .heroDiv {
    border-radius: 5%;
    background-color: white;
    padding: 9% 5%;
    margin: 5% 0 0 0;
    box-shadow: 0px 0px 20px 0px #00000029;
    position: absolute !important;
    top: 13%;
    right: 12%;
  }
}

@media screen and (min-width: 1400px) {
  .heroDiv {
    border-radius: 5%;
    background-color: white;
    padding: 7% 5%;
    margin: 5% 0 0 0;
    box-shadow: 0px 0px 20px 0px #00000029;
    position: absolute !important;
    top: 13%;
    right: 8%;
  }
}

@media (min-width: 1024px) {
  .heroAboutus {
    background-attachment: fixed;
  }
}

@media (max-width: 992px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }
  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }
  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .bgImage {
    background-image: none !important;
    text-align: center;
  }
  .col-width {
    width: 88.33% !important;
    z-index: 1;
  }
  .hero h1 {
    font-size: 32px;
  }
  .hero h2 {
    font-size: 24px;
  }
  .hero .hero-img img {
    width: 100%;
  }
  .hero .btn-get-started {
    margin-top: 0px;
    padding: 20px 40px;
  }
  .marginTop {
    margin-top: -90px !important;
  }
}

@media (max-width: 576px) {
  .bgImage {
    background-image: none !important;
  }
  .hero .btn-get-started {
    margin-top: 0px;
    padding: 20px 40px;
  }
  .col-width {
    width: 88.33% !important;
    z-index: 1;
  }
  .marginTop {
    margin-top: -80px !important;
  }
}

@media (max-width: 380px) {
  .marginTop {
    margin-top: -55px !important;
  }
}
