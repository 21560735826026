.exam-container {
    width: 90%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    margin: 100px auto 50px;
    padding: 20px;
    background-color: whitesmoke;
}

.exam-info {
    border-bottom: 2px solid rgb(243, 67, 37);
}