section {
    padding: 12px 0;
    overflow: hidden;
  }

  /* .row{
    margin-bottom: 12px !important;
  } */

  .card-body{
    box-shadow: 1px 1px 20px 2px #0000001a;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #000000;
    /*text-transform: uppercase;*/
  }
  
  .section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    color: #006FAE;
    letter-spacing: 1px;
  }

  .values .box {
    /*padding: 30px;*/
    /*box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);*/
    text-align: center;
    transition: 0.3s;
    height: 100%;
    padding: 25px 25px;
    /* border: 1px solid black; */
    border-radius: 19px;
    box-shadow: 1px 1px 13px 10px #ababab36;
  }
  
  .values .box img {
    padding: 10px 3px;
    transition: 0.5s;
    transform: scale(1.1);
  }

  .values .card {
    border: 0px !important;
  }

  .values .card-header {
      color: white;
    background-color: #f7802c;
    border-top-right-radius: 36px !important;
    border-top-left-radius: 36px !important;
    text-align: center!important;
    font-size: 23px;
    font-weight: bold;
  }

  .primaryHeader {
    color: white !important;
    background-color: #4665f1 !important;
  }

  .primaryFooter{
    color: white !important;
    background-color: #4665f1 !important;
  }

  .successHeader {
    color: white !important;
    background-color: #28a745 !important;
  }

  .successFooter{
    color: white !important;
    background-color: #28a745 !important;
  }

  .btnSignUp{
        color: white !important;
    font-size: 23px !important;
    font-weight: bold !important;
  }

  .values .card-footer {
    color: white;
  background-color: #f7802c;
    border-bottom-right-radius: 36px !important;
    border-bottom-left-radius: 36px !important;
    text-align: center!important;
    font-size: 23px;
    font-weight: bold;
  }
  
  .values .box h3 {
    font-size: 24px;
    color: #012970;
    font-weight: 700;
    margin-bottom: 18px;
  }

  .values .box h4 {
    color: rgb(247, 127, 43);
    border-bottom: 3px solid;
  }

  .values .box p {
    margin-bottom: 2px !important;
  }
  
  .values .box:hover {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  }
  
  .values .box:hover img {
    transform: scale(1);
  }
  
  @media (max-width: 768px) {
    .section-header p {
      font-size: 28px;
      line-height: 32px;
    }
  }


 .values .nav-link{
      padding: 1rem 3rem !important;
 }

 .values .nav-tabs .nav-link{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
 }

 .values .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #f7802c;
    border-color: #f7802c #f7802c #fff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
 }

@media(max-width:576px){
 .colMargin{
  margin-bottom: 24px;
 }
}