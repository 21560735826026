.pie-chart-c {
  position: relative;
  height: 200px;
}

.content-inside-pie-c {
  /* position: absolute;
  top: 37%;
  left: 61%;
  text-align: center; */
  position: absolute !important;
  left: 50%;
  top: 38%;
  text-align: center;
  transform: translateX(-56%);
}

.review-container {
  width: 90%;
  margin: auto;
}

.review-c-he-01 {
  display: flex;
  justify-content: space-between;
}

.review-info-c {
  display: flex;
  align-items: center;
}

.qa-card-ind {
  box-shadow: 0px 1px 3px -1px black;
  padding: 30px;
  margin-bottom: 40px;
}

.answer-is-wrong {
  background-color: #f34325;
  color: white;
  padding: 20px 5px;
}

.answer-is-right {
  background-color: #4154f1;
  color: white;
  padding: 20px 5px;
}

.not-answered {
  background-color: grey;
  color: white;
  padding: 20px 5px;
}

.alphabet-uppercase-r-t {
  background-color: #d0d0d0;
  border: 1px solid #f34325;
  border-radius: 50px;
  padding: 5px 8px;
  margin-right: 20px;
  background: #f34325;
  color: white;
}
.alphabet-uppercase-r-f {
  background-color: #f34325;
  border: 1px solid #f34325;
  color: white;
  border-radius: 50px;
  padding: 3px 8px;
  margin-right: 20px;
}

.alphabet-uppercase-w-t {
  background-color: #d0d0d0;
  border: 1px solid #4154f1;
  border-radius: 50px;
  padding: 3px 8px;
  margin-right: 20px;
  background: #4154f1;
  color: white;
}
.alphabet-uppercase-w-f {
  background-color: #4154f1;
  border: 1px solid #4154f1;
  color: white;
  border-radius: 50px;
  padding: 3px 8px;
  margin-right: 20px;
}

.option-answers-c {
  display: flex;
}
.option-alphabet-cc p {
  margin-right: 15px;
  text-transform: uppercase;
  padding: 3px 10px;
  border-radius: 50px;
  background-color: #d0d0d0;
}

.alphabet-uppercase-w-2 {
  background-color: #d0d0d0;
  border: 1px solid #d0d0d0;
  border-radius: 50px;
  padding: 3px 8px;
  margin-right: 20px;
}

.indi-options-c {
  width: 98%;
  margin: auto;
  display: flex;
}

.indi-options-c p {
  margin: 0 !important;
}

.alphabet-uppercase-r-t-n {
  background-color: #d0d0d0;
  border: 1px solid #f34325;
  border-radius: 50px;
  margin-right: 20px;
  background: #f34325;
  display: block;
  color: white;
  width: 26px;
  text-align: center;
}

.answer-not-attempted {
  border: 1px solid #d0d0d0;
  border-radius: 50px;
  margin-right: 5px;
  background: #d0d0d0;
  display: block;
  color: white;
  width: 26px;
  text-align: center;
}

.correct-answer {
  border: 1px solid #4154f1;
  border-radius: 50px;
  margin-right: 5px;
  background: #4154f1;
  display: block;
  color: white;
  width: 26px;
  text-align: center;
}

.wrong-answer {
  border: 1px solid #f34325;
  border-radius: 50px;
  margin-right: 5px;
  background: #f34325;
  display: block;
  color: white;
  width: 26px;
  text-align: center;
}

.question-answer-ff {
  display: flex;
}

.check-ans-cf {
  width: 20px;
}
