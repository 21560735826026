.test-container {
    width: 90%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    margin: 100px auto 50px;
    background-color: whitesmoke;
}

.test-container>h5 {
    font-size: 20px;
    color: #f34325;
    margin: 20px 0 24px 40px;
}

.tableDiv {
    display: flex;
    justify-content: center;
}

.tableDiv table {
    width: 85%;
}

.tableDiv th {
    background-color: orange;
    color: #444444;
    height: 48px;
    font-size: 18px;
}

.tableDiv th,
.tableDiv td {
    border: 1px solid #444444;
    text-align: center;
}

.tableDiv td {
    height: 48px;
}

.tableDiv td p {
    margin: 8px 0px;
}

.test-container>button {
    width: 14%;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    padding: 4px;
    margin: 50px auto;
}

.test-result {
    width: 90%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    background-color: whitesmoke;
}