.heroContact {
    width: 100%;
    height: 60vh;
    /* background: url(../img/login/LoginPageBG.png) bottom center no-repeat; */
    background-size: cover !important;
    /*background-position: bottom center !important;*/
    margin: 4% 0 0% 0;
  }

  .heroContact .btn-get-started {
    margin-top: 30px;
    line-height: 0;
    padding: 30px 60px;
    border-radius: 20px;
    transition: 0.5s;
    color: #fff;
    background: #4154f1;
    box-shadow: 0px 5px 30px rgb(65 84 241 / 40%);
  }
  
  .heroContact .btn-get-started span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .heroContact .btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
  }
  
  .heroContact .btn-get-started:hover i {
    transform: translateX(5px);
  }
.wrapperc{
    width:100% !important;
    -webkit-box-shadow:0 21px 41px -13px rgba(0,0,0,.18);
    -moz-box-shadow:0 21px 41px -13px rgba(0,0,0,.18);
    box-shadow:0 21px 41px -13px rgba(0,0,0,.18)
}
.contact-wrap{
    background:#fff
}
.info-wrap{
    color:rgba(255,255,255,.8)
}
.info-wrap h3{
    color:#fff
}
.info-wrap .dbox{
    width:100%;
    color:rgba(255,255,255,.8);
    margin-bottom:25px
}
.info-wrap .dbox:last-child{
    margin-bottom:0
}
.info-wrap .dbox p{
    margin-bottom:0
}
.info-wrap .dbox p span{
    font-weight:500;
    color:#fff
}
.info-wrap .dbox p a{
    color:#fff
}
.info-wrap .dbox .icon{
    width:50px;
    height:50px;
    border-radius:50%;
    border:2px solid rgba(255,255,255,.2)
}
.info-wrap .dbox .icon span{
    font-size:20px;
    color:#fff
}
.info-wrap .dbox .text{
    width:calc(100% - 50px)
}
.contactForm .label{
    color:#000;
    text-transform:uppercase;
    font-size:12px;
    font-weight:600
}

#contactForm .form-control {
    font-size: 16px;
}
.contactForm .form-control{
    border:none;
    border-bottom:2px solid rgba(0,0,0,.1);
    padding:0
}
.form-control{
    height:36px;
    background:#fff;
    color:rgba(0,0,0,.8);
    font-size:14px;
    border-radius:2px;
    -webkit-box-shadow:none!important;
    box-shadow:none!important;
    border:1px solid rgba(0,0,0,.1)
}
.form-control:focus,.form-control:active{
    border-color:#4665f1!important
}
textarea.form-control{
    height:inherit!important
}

@media (max-width:576px){
    .heroContact{
        height: 50vh;
    }
  }
