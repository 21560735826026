.sectionBannerGre{
      margin-top: 6% !important;
    background-size: cover !important;
    background-position: inherit !important;
    background-image:  url(../../Assets/GREW.jpeg);
}

.sectionBannerGmat{
      margin-top: 6% !important;
    background-size: cover !important;
    background-position: inherit !important;
    background-image:  url(../../Assets/GMATW.jpeg);
}
.sectionBannerIelts{
      margin-top: 6% !important;
    background-size: cover !important;
    background-position: inherit !important;
    background-image:  url(../../Assets/IELTSW.jpeg);
}
.sectionBannerToefl{
      margin-top: 6% !important;
    background-size: cover !important;
    background-position: inherit !important;
    background-image:  url(../../Assets/TOEFLW.jpeg);
}

.u-section-1 {
  
  background-position: 50% 50%;
}

.u-section-1 .u-sheet-1 {
  min-height: 388px;
}

.u-section-1 .u-text-1 {
  margin: 60px auto 0;
}

.u-section-1 .u-text-2 {
  margin: 20px 20px 0;
}

.u-section-1 .u-btn-1 {
  text-transform: uppercase;
  margin: 30px auto 60px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 320px;
  }

  .u-section-1 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 245px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 184px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 116px;
  }
}.u-section-2 {
/*  background-image: url("images/dff.png");
*/  background-position: 50% 93.89%;
}

.u-section-2 .u-sheet-1 {
  min-height: 827px;
}

.u-section-2 .u-text-1 {
  font-size: 3rem;
  width: 681px;
  margin: 53px auto 0;
  color: #0148b4;
}

.u-section-2 .u-text-2 {
  font-weight: 400;
  font-style: italic;
  width: 674px;
  font-size: 1.125rem;
  margin: 34px auto 0;
}

.u-section-2 .u-btn-1 {
  font-style: italic;
  border-style: none none solid;
  letter-spacing: 0px;
  padding: 0;
}

.u-section-2 .u-list-1 {
  margin-top: 55px;
  margin-bottom: 60px;
}

.u-section-2 .u-repeater-1 {
  grid-template-columns: repeat(2, calc(50.3333% - 10.3333px));
  min-height: 436px;
  grid-gap: 20px;
}

.u-section-2 .u-container-layout-1 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-4 {
  margin: -37px 0 0 41px;
}

.u-section-2 .u-container-layout-2 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-6 {
  margin: -39px 0 0 41px;
}

.u-section-2 .u-container-layout-3 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-7 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-8 {
  margin: -39px 0 0 41px;
}

.u-section-2 .u-container-layout-4 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-9 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-10 {
  margin: -39px 0 0 41px;
}

.u-section-2 .u-container-layout-5 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-11 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-12 {
  margin: -39px 0 0 41px;
}

.u-section-2 .u-container-layout-6 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-13 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-14 {
  margin: -39px 0 0 41px;
}

.u-section-2 .u-container-layout-7 {
  padding: 10px 30px 14px;
}

.u-section-2 .u-text-15 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
}

.u-section-2 .u-text-16 {
  margin: -39px 0 0 41px;
}

@media (max-width: 1199px) {
  .u-section-2 .u-sheet-1 {
    min-height: 682px;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: repeat(3, calc(33.333333333333336% - 13.3333px));
    min-height: 360px;
  }

  .u-section-2 .u-text-4 {
    width: auto;
  }

  .u-section-2 .u-text-6 {
    width: auto;
  }

  .u-section-2 .u-text-8 {
    width: auto;
  }

  .u-section-2 .u-text-10 {
    width: auto;
  }

  .u-section-2 .u-text-12 {
    width: auto;
  }

  .u-section-2 .u-text-14 {
    width: auto;
  }
}

@media (max-width: 991px) {
  .u-section-2 .u-sheet-1 {
    min-height: 522px;
  }

  .u-section-2 .u-text-1 {
    font-size: 4.0625rem;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: repeat(2, calc(50% - 9.999975px));
    min-height: 620px;
  }

  .u-section-2 .u-text-4 {
    margin-left: 34px;
  }

  .u-section-2 .u-text-6 {
    margin-left: 34px;
  }

  .u-section-2 .u-text-8 {
    margin-left: 34px;
  }

  .u-section-2 .u-text-10 {
    margin-left: 34px;
  }

  .u-section-2 .u-text-12 {
    margin-left: 34px;
  }

  .u-section-2 .u-text-14 {
    margin-left: 34px;
  }
}

@media (max-width: 767px) {
  .u-section-2 {
    background-position: 33.35% 93.89%;
  }

  .u-section-2 .u-sheet-1 {
    min-height: 392px;
  }

  .u-section-2 .u-text-1 {
    font-size: 3rem;
    width: 540px;
  }

  .u-section-2 .u-text-2 {
    width: 540px;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-4 {
    margin-left: 46px;
  }

  .u-section-2 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-6 {
    margin-left: 46px;
  }

  .u-section-2 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-8 {
    margin-left: 46px;
  }

  .u-section-2 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-10 {
    margin-left: 46px;
  }

  .u-section-2 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-12 {
    margin-left: 46px;
  }

  .u-section-2 .u-container-layout-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-14 {
    margin-left: 46px;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-sheet-1 {
    min-height: 247px;
  }

  .u-section-2 .u-text-1 {
    font-size: 1.5rem;
    width: 340px;
  }

  .u-section-2 .u-text-2 {
    width: 340px;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: 100%;
  }

  .u-section-2 .u-text-4 {
    margin-top: 20px;
    margin-left: 0;
  }

  .u-section-2 .u-text-6 {
    margin-top: 20px;
    margin-left: 0;
  }

  .u-section-2 .u-text-8 {
    margin-top: 20px;
    margin-left: 0;
  }

  .u-section-2 .u-text-10 {
    margin-top: 20px;
    margin-left: 0;
  }

  .u-section-2 .u-text-12 {
    margin-top: 20px;
    margin-left: 0;
  }

  .u-section-2 .u-text-14 {
    margin-top: 20px;
    margin-left: 0;
  }
}.u-section-3 .u-sheet-1 {
  min-height: 639px;
}

.u-section-3 .u-text-1 {
  margin: 38px auto 0;
}

.u-section-3 .u-list-1 {
  width: 1060px;
  grid-template-rows: repeat(3, auto);
  margin: 11px auto 55px;
}

.u-section-3 .u-repeater-1 {
  grid-template-columns: repeat(1, 100%);
  min-height: 500px;
  grid-gap: 10px;
}

.u-section-3 .u-container-layout-1 {
  padding: 30px 30px 1px;
}

.u-section-3 .u-icon-1 {
  height: 64px;
  width: 64px;
  margin: 0 auto -20px 44px;
}

.u-section-3 .u-text-2 {
  margin: -64px 0 0 115px;
}

.u-section-3 .u-container-layout-2 {
  padding: 30px 30px 1px;
}

.u-section-3 .u-icon-2 {
  height: 64px;
  width: 64px;
  margin: 0 auto -20px 44px;
}

.u-section-3 .u-text-3 {
  margin: -64px 0 0 115px;
}

.u-section-3 .u-container-layout-3 {
  padding: 30px 30px 1px;
}

.u-section-3 .u-icon-3 {
  height: 64px;
  width: 64px;
  margin: 0 auto -9px 44px;
}

.u-section-3 .u-text-4 {
  margin: -64px 0 0 115px;
}

.u-section-3 .u-container-layout-4 {
  padding: 30px 30px 1px;
}

.u-section-3 .u-icon-4 {
  height: 64px;
  width: 64px;
  margin: 0 auto -20px 44px;
}

.u-section-3 .u-text-5 {
  margin: -64px 0 0 115px;
}

.u-section-3 .u-container-layout-5 {
  padding: 30px 30px 1px;
}

.u-section-3 .u-icon-5 {
  height: 64px;
  width: 64px;
  margin: 0 auto -9px 44px;
}

.txtJust{
  text-align: justify !important;
}

.u-section-3 .u-text-6 {
  margin: -64px 0 0 115px;
}

@media (max-width: 1199px) {
  .u-section-3 .u-list-1 {
    width: 940px;
    height: auto;
  }

  .u-section-3 .u-text-2 {
    margin-left: 0;
  }

  .u-section-3 .u-text-3 {
    margin-left: 0;
  }

  .u-section-3 .u-text-4 {
    margin-left: 0;
  }

  .u-section-3 .u-text-5 {
    margin-left: 0;
  }

  .u-section-3 .u-text-6 {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-list-1 {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-list-1 {
    width: 540px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-2 {
    margin-left: 115px;
  }

  .u-section-3 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-3 {
    margin-left: 115px;
  }

  .u-section-3 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-4 {
    margin-left: 115px;
  }

  .u-section-3 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-5 {
    margin-left: 115px;
  }

  .u-section-3 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-6 {
    margin-left: 115px;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-list-1 {
    width: 340px;
  }
}.u-section-4 {
  background-image: none;
}

.u-section-4 .u-sheet-1 {
  min-height: 762px;
}

.u-section-4 .u-list-1 {
  width: 533px;
  margin: 71px auto 0 0;
}

.u-section-4 .u-repeater-1 {
  grid-template-columns: repeat(1, 100%);
  min-height: 608px;
  grid-gap: 0px;
}

.u-section-4 .u-container-layout-1 {
  padding: 10px 30px 14px;
}

.u-section-4 .u-text-1 {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.u-section-4 .u-text-2 {
  margin: -60px 0 0 35px;
}

.u-section-4 .u-container-layout-2 {
  padding: 10px 30px 14px;
}

.u-section-4 .u-text-3 {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.u-section-4 .u-text-4 {
  margin: -54px 0 0 35px;
}

.u-section-4 .u-container-layout-3 {
  padding: 10px 30px 14px;
}

.u-section-4 .u-text-5 {
  margin-top: -30px;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.u-section-4 .u-text-6 {
  margin: -38px 0 0 35px;
}

.u-section-4 .u-container-layout-4 {
  padding: 10px 30px 14px;
}

.u-section-4 .u-text-7 {
  margin-top: -5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.u-section-4 .u-text-8 {
  margin: -45px 0 0 35px;
}

.u-section-4 .u-container-layout-5 {
  padding: 10px 30px 14px;
}

.u-section-4 .u-text-9 {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.u-section-4 .u-text-10 {
  margin: -43px 0 0 35px;
}

.u-section-4 .u-container-layout-6 {
  padding: 10px 30px 0;
}

.u-section-4 .u-text-11 {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

.u-section-4 .u-text-12 {
  margin: -40px 0 0 35px;
}

.u-section-4 .u-group-1 {
  width: 493px;
  min-height: 632px;
  background-image: none;
  margin: -690px 0 60px auto;
}

.u-section-4 .u-container-layout-7 {
  padding: 45px 30px 45px;
}

.u-section-4 .u-image-1 {
  width: 240px;
  height: 240px;
  margin: 0 auto;
}

.u-section-4 .u-text-13 {
  font-weight: 400;
  font-size: 3rem;
  margin: 20px auto 0;
}

.u-section-4 .u-text-14 {
  margin: 16px 0 0;
}

.u-section-4 .u-btn-1 {
  background-image: none;
  text-transform: uppercase;
  font-size: 1.125rem;
  letter-spacing: 3px;
  border-style: none;
  font-weight: 700;
  margin: 35px auto 0;
  padding: 13px 32px 14px 31px;
}

.u-section-4 .u-icon-1 {
  color: rgb(17, 17, 17) !important;
}

.u-section-4 .u-text-15 {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.8;
  margin: 20px auto 0;
}

.u-section-4 .u-btn-2 {
  border-style: none none solid;
  padding: 0;
}

@media (max-width: 1199px) {
  .u-section-4 .u-repeater-1 {
    grid-template-columns: 100%;
  }

  .u-section-4 .u-group-1 {
    width: 386px;
    margin-top: -608px;
    margin-bottom: -500px;
  }
}

@media (max-width: 991px) {
  .u-section-4 .u-sheet-1 {
    min-height: 1100px;
  }

  .u-section-4 .u-list-1 {
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-4 .u-group-1 {
    margin-top: 18px;
    margin-bottom: 60px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-4 .u-text-14 {
    width: 475px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .u-section-4 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-7 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 575px) {
  .u-section-4 .u-container-layout-1 {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-text-1 {
    font-size: 2.25rem;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .u-section-4 .u-text-2 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }

  .u-section-4 .u-container-layout-2 {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-text-3 {
    font-size: 2.25rem;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .u-section-4 .u-text-4 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }

  .u-section-4 .u-container-layout-3 {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-text-5 {
    font-size: 2.25rem;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .u-section-4 .u-text-6 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }

  .u-section-4 .u-container-layout-4 {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-text-7 {
    font-size: 2.25rem;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .u-section-4 .u-text-8 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }

  .u-section-4 .u-container-layout-5 {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-text-9 {
    font-size: 2.25rem;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .u-section-4 .u-text-10 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }

  .u-section-4 .u-container-layout-6 {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-text-11 {
    font-size: 2.25rem;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .u-section-4 .u-text-12 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }

  .u-section-4 .u-text-14 {
    width: 320px;
  }

  .u-section-4 .u-text-15 {
    width: auto;
    margin: -39px 36px 0px;
  }
  .u-section-4 .u-text-16 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }
  .u-section-4 .u-text-17 {
    width: auto;
    margin-top: -51px;
    margin-left: 49px;
  }
  
}


.advTag{
    color: #006fae;
    font-weight: 600;
    font-family: inherit;
 }