.checklist-answer-c {
  justify-content: space-between;
  display: flex;
}

/* Hide the browser's default checkbox */
.container-fc-if input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  background: white;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  /* border:1px solid black */
}

/* On mouse-over, add a grey background color   background-color: blue;
  */
.container-fc-if:hover input ~ .checkmark {
}

/* When the checkbox is checked, add a blue background */
.container-fc-if input:checked ~ .checkmark {
  background-color: #0879fa;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: block;
}

/* Show the checkmark when checked */
.container-fc-if .hide-in input:checked ~ .checkmark:after {
  display: none;
}

/* Style the checkmark/indicator */
.container-fc-if .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Create a custom radio button */
.dotmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-fc-if:hover input ~ .dotmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-fc-if input:checked ~ .dotmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.dotmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-fc-if input:checked ~ .dotmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-fc-if .dotmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

@media(max-width:576px){
  .checklist-answer-c {
    margin-top: 24px !important;
  }
}